<script>

export default {
  props: {
    title: String,
    image: Object,
    excerpt: String,
    url: String
  },
  setup(props) {
    // setup() receives props as the first argument.
  }
}

</script>

<template>
  <a :href="url" class="">
  <div class="w-full md:w-96 ">
    <div class="relative border-gray-200 dark:border-gray-900 rounded-sm hover:shadow-xl z-10">
      <img alt="Wil winters imagining" :src="image.src" :alt="image.alt" loading="eager" decoding="async"
        class=" object-cover h-52 md:w-96" :width="image.width" :height="image.height">
    </div>
    <div class="dark:bg-dark-alt bg-light-alt rounded-b-lg px-4">
      <h3 class="h-16 pt-4 font-bold text-lg text-primary dark:text-primary text-center tracking-wide mt-0">
        {{ title }}
      </h3>
      <p class="pt-0 overflow-y-auto text-sm font-light topleading-none top-0 pb-6 h-32 text-gray-800 dark:text-gray-400">
        {{ excerpt }}
      </p>
    </div>
  </div>
  </a>
</template>
