<script>
import MiniSearch from 'minisearch';
import SearchResultVue from '~/components/common/SearchResult.vue';

let miniSearch = new MiniSearch({
  fields: ['title', 'text', 'searchterms', 'tags'],
  storeFields: ['title', 'url', 'text', 'collection', 'image'],
});

// I couldn't pass the document from an Atro component to client-side vue
// cleanly so I bodged up this document.variabelname hack
miniSearch.addAll(window.searchdata);

export default {
  components: {
    SearchResultVue
  },
  data() {
    return {
      open: false,
      term: "",
      results: [],
      miniSearch: miniSearch,
      // suggestions: [],
    };
  },
  computed: {
    echo() {
      return "ECHO: " + this.term;
    },

    hasResult() {
      return this.books.length + this.series.length + this.wikis.length + this.tales.length > 0
    },
    search() {
      this.results = this.miniSearch.search(this.term, { boost: { title: 2 }, prefix: true, fuzzy: 0.2 });
      // console.log("searcv:", this.results);
      return this.results;
      // return this.results 
    },
    books() {
      // console.log("books", this.search.filter((item) => item.collection == "book"));
      return this.search.filter((item) => item.collection == "book");
    },
    series() {
      //    console.log('series', this.search.filter((item) => item.collection == 'series'));
      return this.search.filter((item) => item.collection == "series");
    },
    wikis() {
      //    console.log('wiki', this.search.filter((item) => item.collection == 'wiki'));
      return this.search.filter((item) => item.collection == "wiki");
    },
    tales() {
      //    console.log('tale', this.search.filter((item) => item.collection == 'tale'));
      return this.search.filter((item) => item.collection == "tale");
    },
  },
  methods: {
    focusInput() {
      setTimeout(() => {
        this.$refs.searchinput.focus();
        window.scrollTo(0, 0);
      }, 100)

    },
  },
}

</script>


<template>
  <div v-if="open" v-on:keydown.esc="open = false"
    class="ontop fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto pb-96 bg-light dark:bg-dark" id="scollModal"
    tabindex="-1" aria-labelledby="scrollModal" aria-hidden="true">
    <div class="xsm:h-[calc(100%-3rem)] xmax-w-lg xmy-6 mx-auto relative w-auto pointer-events-none ">
      <div
        class="max-h-full overflow-hidden border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding xrounded-md outline-none text-current bg-light dark:bg-black min-h-screen">
        <div
          class="flex flex-shrink-0 items-center justify-between p-4 border-primary  border-b xborder-gray-200 rounded-t-md">

          <!--  search box -->
          <div class="box pt-6 w-full">
            <div class="box-wrapper">
              <div
                class="dark:bg-dark border-primary  bg-light rounded flex items-center w-full md:w-96  mx-auto p-3 shadow-sm border border-gray-200">
                <input type="search" name="" id="" ref="searchinput" v-model="term" placeholder="Search..."
                  class="w-full text-gray-400 pl-4 text-sm outline-none focus:outline-none bg-transparent" />
                <div @click="term = ''" class="text-gray-600">X</div>
              </div>
            </div>
          </div>

          <!-- close button -->
          <div class="pl-4 pt-6">
            <button type="button" @click="open = false"
              class="btn-close box-content text-primary w-8 h-8 border-primary border-2 rounded-full xopacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:bg-primary hover:text-gray-700"
              data-bs-dismiss="modal" aria-label="Close">X</button>
          </div>
        </div>
        <div class="flex-auto overflow-y-auto relative">

          <!-- hero -->
          <div v-if="!term" class="hero">
            <div class="hero-headline flex flex-col items-center justify-center pt-24 text-center">
              <h1 class="font-bold text-3xl text-gray-700">Search books, series, wiki articles and tall tales</h1>
              <p class="font-base pt-4 text-base text-gray-500">
                Type your query into the search box above
              </p>
            </div>
          </div>
          
          <!-- result counts -->
          <div v-if="term" class="hero pb-8">
            <div class="hero-headline flex flex-col items-center justify-center pt-8 text-center">
              <h1 class="font-bold text-3xl text-gray-700">Results</h1>
              <p class="font-base text-lg pt-4 text-base text-gray-500" :class="this.books.length == 0 || 'text-primary'">
                <a href="#ref_books">{{ this.books.length }} books</a>
              </p>
              <p class="font-base text-lg pt-4 text-base text-gray-500"  :class="this.series.length == 0 || 'text-primary'">
                <a href="#ref_series">{{ this.series.length }} series</a>
              </p>
              <p class="font-base text-lg pt-4 text-base text-gray-500"  :class="this.wikis.length == 0 || 'text-primary'">
                <a href="#ref_wikis">{{ this.wikis.length }} wiki articles</a>
              </p>
              <p class="font-base text-lg pt-4 text-base text-gray-500"  :class="this.tales.length == 0 || 'text-primary'">
                <a href="#ref_tales">{{ this.tales.length }} tall tales</a>
              </p>
            </div>
          </div>

          <!-- results -->
          <div class="">

            <!-- books -->
            <div id="ref_books" v-if="books.length > 0"
              class="md:px-20 px-2 pt-8 odd:bg-white even:bg-slate-50 odd:dark:bg-dark even:dark:bg-dark-alt">
              <h2 class="pb-4 text-3xl lg:text-4xl font-bold font-heading pl-0 md:pl-0">Books</h2>
              <div class="">Check out these books by Wil Winters.</div>
              <div class="flex flex-wrap gap-y-10 pt-6 pb-6 md:gap-y-6 md:gap-x-6 w-full mx-auto xmd:pl-2">
                <template v-for="item in books" :key="item.url">
                  <SearchResultVue :title="item.title" :url="'/' + item.url" :excerpt="item.text" :image="item.image">
                  </SearchResultVue>
                </template>
              </div>
            </div>

            <!-- series -->
            <div id="ref_series" v-if="series.length > 0"
              class="md:px-20 px-2 pt-8 odd:bg-white even:bg-slate-50 odd:dark:bg-dark even:dark:bg-dark-alt">
              <h2 class="pb-4 text-3xl lg:text-4xl font-bold font-heading pl-0 md:pl-0">Series</h2>
              <div class="">Have you read all the books in these serieseses?</div>
              <div class="flex flex-wrap gap-y-10 pt-6 pb-6 md:gap-y-6 md:gap-x-6 w-full mx-auto xmd:pl-2">
                <template v-for="item in series" :key="item.url">
                  <SearchResultVue :title="item.title" :url="'/' + item.url" :excerpt="item.text" :image="item.image">
                  </SearchResultVue>
                </template>
              </div>
            </div>

            <!-- wikis -->
            <div id="ref_wikis" v-if="wikis.length > 0"
              class="md:px-20 px-2 pt-8 odd:bg-white even:bg-slate-50 odd:dark:bg-dark even:dark:bg-dark-alt">
              <h2 class="pb-4 text-3xl lg:text-4xl font-bold font-heading pl-0 md:pl-0">Wiki articles</h2>
              <div class="">Get yourself educated over at the wiki!</div>
              <div class="flex flex-wrap gap-y-10 pt-6 pb-6 md:gap-y-6 md:gap-x-6 w-full mx-auto xmd:pl-2">
                <template v-for="item in wikis" :key="item.url">
                  <SearchResultVue :title="item.title" :url="'/' + item.url" :excerpt="item.text" :image="item.image">
                  </SearchResultVue>
                </template>
              </div>
            </div>

            <!-- tales -->
            <div id="ref_tales" v-if="tales.length > 0"
              class="md:px-20 px-2 pt-8 odd:bg-white even:bg-slate-50 odd:dark:bg-dark even:dark:bg-dark-alt">
              <h2 class="pb-4 text-3xl lg:text-4xl font-bold font-heading pl-0 md:pl-0">Tall tales</h2>
              <div class="">Strange stories plucked from the life of Wil Winters.</div>
              <div class="flex flex-wrap gap-y-10 pt-6 pb-6 md:gap-y-6 md:gap-x-6 w-full mx-auto xmd:pl-2">
                <template v-for="item in tales" :key="item.url">
                  <SearchResultVue :title="item.title" :url="'/' + item.url" :excerpt="item.text" :image="item.image">
                  </SearchResultVue>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <button type="button" @click="open = true; focusInput();"
    class="text-muted dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm md:p-1 inline-flex items-center"
    aria-label="search">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path fill="currentColor"
        d="m19.6 21l-6.3-6.3q-.75.6-1.725.95Q10.6 16 9.5 16q-2.725 0-4.612-1.887Q3 12.225 3 9.5q0-2.725 1.888-4.613Q6.775 3 9.5 3t4.613 1.887Q16 6.775 16 9.5q0 1.1-.35 2.075q-.35.975-.95 1.725l6.3 6.3ZM9.5 14q1.875 0 3.188-1.312Q14 11.375 14 9.5q0-1.875-1.312-3.188Q11.375 5 9.5 5Q7.625 5 6.312 6.312Q5 7.625 5 9.5q0 1.875 1.312 3.188Q7.625 14 9.5 14Z" />
    </svg>
  </button>
</template>


<style scoped>
.xmodal {
  background-color: black;
  position: fixed;
  z-index: 999;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  /* margin-left: -150px; */
}

.modal {
  transition: opacity 0.25s ease;
}

body.modal-active {
  overflow-x: hidden;
  overflow-y: visible !important;
}

.opacity-95 {
  opacity: .95;
}</style>